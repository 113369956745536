<template>
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper-banner">
      <template v-if="banners.length > 0">
        <swiper-slide v-for="(item, index) in banners" :key="index">
          <AppLink :to="`/activitys/activityDetail?id=${item.id}`">
            <div class="swiper-slide-item">
                <img :src="item.banner | formatUrl" class="swiper-slide-item-image" />
                <div v-if="item.hideMask !== 1" class="title" data-swiper-parallax="-100">{{item.title}}</div>
                <div v-if="item.hideMask !== 1" class="subTitle" data-swiper-parallax="-300">{{item.startTime | trimSecond}} - {{item.endTime | trimSecond}}</div>
                <div v-if="item.hideMask !== 1" class="mask"></div>
            </div>
          </AppLink>
        </swiper-slide>
      </template>
      <template v-else>
        <swiper-slide>
          <img src="../assets/banner.jpg" class="swiper-slide-item" />
        </swiper-slide>
      </template>
      <div class="swiper-button-prev" v-show="banners.length > 1" slot="button-prev"></div>
      <div class="swiper-button-next" v-show="banners.length > 1" slot="button-next"></div>
    </swiper>
</template>

<script>
import AppLink from '@/components/AppLink.vue'
export default {
  name: 'Banner',
  components: {
    AppLink
  },
  props: {
    banners: {
      type: Array,
      // 对象或数组默认值必须从一个工厂函数获取
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      swiperOptions: {
        loop: this.banners.length > 0,
        initialSlide: 0,
        speed: 1000,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        lazy: {
          loadPrevNext: true
        },
        parallax: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
    @import url('@/variable.less');
    .swiper-banner {
      width: @base-width;
      height: 320px;
      margin: auto;
    }
    .swiper-slide-item {
        height: 100%;
        width: 100%;
        position: relative;
        .swiper-slide-item-image {
          width: 100%;
          height: 100%;
          object-fit:cover;
          object-position: 50% 50%;
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.3);
          z-index: 1;
        }
        .title, .subTitle {
          width: 100%;
          position: absolute;
          text-align: center;
          color: #fff;
          z-index: 2;
          text-shadow: 1px 1px 2px rgb(71, 68, 68), 0 0 1em rgb(29, 29, 35), 0 0 0.2em rgb(29, 29, 35);
        }
        .title {
          top: 120px;
          font-size: 25px;
        }
        .subTitle{
          top: 160px;
          font-size: 20px;
        }
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        background-image: url("../assets/prev.png");
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
        left: 5%;
        right: auto;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        background-image: url("../assets/next.png");
        background-size: 35px 35px;
        width: 35px;
        height: 35px;
        right: 5%;
        left: auto;
    }
</style>
