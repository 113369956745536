<template>
    <div>
      <div class="mask-container" v-if="showBtn">
          <div class="mask"></div>
          <div class="btn" @click="goLogin">点击手机扫码登录查看详情</div>
      </div>
      <Modal :title="title" width="400px" v-if="showModal" @close="cancelModal">
          <div slot="body" class="model-container">
              <div class="qrcode-container">
                <img :src="qrcode" class="qrcode" />
                <!-- <div class="qrcode-container-mask"></div> -->
              </div>
              <!-- <div>手机扫描二维码前往授权登录</div> -->
              <div class="qrcode-tip">如小程序授权登录不成功，请 <a href="javascript:void(0)" @click="refresh">刷新</a> 重新扫码登录</div>
          </div>
      </Modal>
    </div>
</template>

<script>
import * as api from '@/api'
import Modal from '@/components/Modal.vue'
import Vue from 'vue'
import { mapState } from 'vuex'
export default {
  name: 'ProductMask',
  components: {
    Modal
  },
  props: {
    title: {
      type: String,
      default: '扫码登录'
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: false,
      qrcode: '',
      scene: '',
      lockReconnect: false,
      timer: null
    }
  },
  computed: mapState({
    currentCountry: state => state.currentCountry
  }),
  methods: {
    refresh () {
      this.fetchQRCode()
    },
    fetchQRCode () {
      this.$loading(true)
      return api.fetchQRCode({ country: this.currentCountry?.code }).then(res => {
        if (res.success) {
          const { result = {} } = res
          const { qrcode, scene } = result
          this.qrcode = qrcode
          this.scene = scene
          this.$nextTick(() => {
            this.initWebSocket()
          })
        } else {
          this.$notify({ title: '', text: res.message, type: 'warn' })
        }
      }).finally(() => {
        this.$loading(false)
      })
    },
    initWebSocket: function () {
      if (typeof WebSocket === 'undefined') {
        this.$notify({ title: '', text: '当前浏览器不支持WebSocket', type: 'error' })
      } else {
        console.log('------------WebSocket连接成功')
        const url = window._CONFIG.systemURL.replace('https://', 'wss://').replace('http://', 'ws://') + '/messageWebsocket/' + this.scene
        // update-begin-author:taoyan date:2022-4-22 for:  v2.4.6 的 websocket 服务端，存在性能和安全问题。 #3278
        this.websock = new WebSocket(url)
        // update-end-author:taoyan date:2022-4-22 for:  v2.4.6 的 websocket 服务端，存在性能和安全问题。 #3278
        this.websock.onopen = this.websocketOnopen
        this.websock.onerror = this.websocketOnerror
        this.websock.onmessage = this.websocketOnmessage
        this.websock.onclose = this.websocketOnclose
      }
    },
    websocketOnopen: function () {
      console.log('WebSocket连接成功')
      // const that = this
      // // 模拟心跳
      // this.timer = setInterval(() => {
      //   that.websocketSend('HeartBeat')
      // }, 20000)
    },
    websocketOnerror: function (e) {
      console.log('WebSocket连接发生错误')
      this.reconnect()
    },
    websocketOnmessage: function (e) {
      console.log('-----接收消息-------', e.data)
      /* eslint-disable no-eval */
      if (e.data) {
        Vue.ls.set('ACCESS_TOKEN', e.data)
      }
      console.log(Vue.ls.get('ACCESS_TOKEN'), 'ACCESS_TOKEN')
      // clearInterval(this.timer)
      // // 关闭socket
      // this.websock.close()
      location.reload()
    },
    websocketOnclose: function (e) {
      console.log('connection closed (' + e + ')')
      if (e) {
        console.log('connection closed ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
      }
      this.reconnect()
    },
    reconnect () {
      const that = this
      if (that.lockReconnect) return
      that.lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        console.info('尝试重连...')
        that.initWebSocket()
        that.lockReconnect = false
      }, 20000)
    },
    websocketSend (text) { // 数据发送
      try {
        this.websock.send(text)
      } catch (err) {
        console.log('send failed (' + err.code + ')')
      }
    },
    goLogin () {
      this.fetchQRCode().then(() => {
        this.showModal = true
      }).catch(e => {
        this.$notify({ title: '', text: e.message, type: 'error' })
      })
    },
    cancelModal () {
      this.showModal = false
    }
  }
}
</script>

<style scoped lang="less">
.mask-container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FFFFFF 100%);
}
  .model-container {
    padding: 40px;
    padding-top: 0;
    text-align: center;
    font-size: 12px;
    color: #666;
  }
.btn{
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 384px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-weight: 600;
    border: 1px #ccc solid;
    font-weight: 400;
    color: #333;
    font-size: 16px;
    cursor: pointer;
}
.qrcode-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
}
.qrcode {
  width: 100%;
  height: 100%;
}
.qrcode-container-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
.qrcode-tip {
  margin-top: 10px;
}
</style>
