
import Vue from 'vue'
import { DIS_PERSONALLY_NUM } from '@/utils/contants'

export const formatUrl = (u) => {
  if((/^(http)s?:\/\//.test(u))) return u
  return `${window._CONFIG.staticDomainURL}/${u}`
}

export const formatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')) {
    return '价格：登录后可见'
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      return `￥${formatIntegerPrice(item.price + item.postage)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return '价格面议'
      }
      if (item.lowestPrice === item.highestPrice) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `￥${formatIntegerPrice(item.lowestPrice + item.postage)} 起`
      }
      return `￥${formatIntegerPrice(item.lowestPrice + item.postage)}~${formatIntegerPrice(item.highestPrice + item.postage)}`
    }
  } else {
    return '价格面议'
  }
}

export const HomeCountryformatPrice = (item, isAll = false) => {
  const formatIntegerPrice = (item) => {
    if (!item) return item
    return item.toLocaleString()
  }
  if (!Vue.ls.get('ACCESS_TOKEN')) {
    return ''
  }
  if (item.type === '01') {
    if (!item.isMuchSpec) {
      // 价格大于九万亿，认为是面议，需要将之前0改为100亿，以前0认为就是面议
      if (item.priceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.priceForHomeCountry + item.postageForHomeCountry)}`
    } else if (item.isMuchSpec && !isAll) {
      // 最小价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
    } else if (item.isMuchSpec && isAll) {
      // 最大价格大于九万亿，认为是面议，需要将之前0改为九万亿，以前0认为就是面议
      if (item.lowestPriceForHomeCountry && item.lowestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return ''
      }
      if (item.lowestPriceForHomeCountry === item.highestPriceForHomeCountry) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}`
      }
      if (item.highestPriceForHomeCountry && item.highestPriceForHomeCountry >= DIS_PERSONALLY_NUM) {
        return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)} 起`
      }
      return `${item.homeCurrencySymbol}${formatIntegerPrice(item.lowestPriceForHomeCountry + item.postageForHomeCountry)}~${formatIntegerPrice(item.highestPriceForHomeCountry + item.postageForHomeCountry)}`
    }
  } else {
    return ''
  }
}

export const trimSecond = (date) => {
  date = date || ''
  const _idx = date.lastIndexOf(':')
  return date.slice(0, _idx)
}
Vue.filter('formatUrl', formatUrl)
Vue.filter('formatPrice', formatPrice)
Vue.filter('trimSecond', trimSecond)
Vue.filter('HomeCountryformatPrice', HomeCountryformatPrice)
