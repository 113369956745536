export const DEFAULT_TENANT_ID = '1000' // 默认印尼

// 服务默认联系人
export const SERVER_DEFAULT_CONTACT = {
  type: 'default',
  name: '罗艳丽',
  phone: '15686296390',
  weixin: require('../assets/wechat.jpg')
}

// 大于等于九万亿，就是等于面议
export const DIS_PERSONALLY_NUM = 9000000000000
